.home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    
    &__pr{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
    }
}